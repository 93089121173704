$(function ($) {

  if ($('.branding-wrapper').length > 0) {

    if ($('.demo-branding').length > 0) {
      $('.demo-branding').addClass('branding-loaded')
    }

    if ($('.branding-wrapper').find('div[class$="-loaded"],div[class*="-loaded"]').length > 0) {
      $('body').addClass('has-branding');
    }

    let intervalId = setInterval(function () {
      if ($('.branding-wrapper').find('div[class$="-loaded"],div[class*="-loaded"]').length > 0) {
        $('body').addClass('has-branding');
        clearInterval(intervalId);
      }
    }, 500);

  }

});
