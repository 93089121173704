$(function ($) {

  // On scroll
  setTimeout(function () {
    onScroll();
    $(document).on('scroll', function () {
      onScroll();
    });
  }, 10);
  let endPosition = 0;

  if ($('.bordered-layout').length > 0 && $('.article-header').length > 0) {
    endPosition = $('.article-header').offset().top + $('.article-header').innerHeight() + $('.bordered-layout .main-column').innerHeight();
  }

  function onScroll() {
    let position = $(window).scrollTop();

    // Fixed share buttons
    if ($('.article-socials').length > 0) {
      if (position + $(window).height() > endPosition) {
        $('.article-socials').addClass('hidden-mobile');
      } else {
        $('.article-socials').removeClass('hidden-mobile');
      }
    }

  }

});
