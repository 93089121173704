$(function($){

  if($(window).width() >= 992) {

    if ($('.gallery-slider').length > 0) {
      let href = $('.close-gallery .icon use').attr('href').split('#')[0];

      $('.gallery-slider').find('.banner-wrapper-small').remove();
      $('.gallery-slider').slick({
        slidesToShow: 1,
        infinite: true,
        fade: true,
        prevArrow: 
        `<button type="button" class="slick-arrow slick-prev">
          <svg class="icon" width="20" height="36" viewBox="0 0 20 36">
            <use href="${href}#icon_arrow"></use>
          </svg>
        </button>`,
        nextArrow: 
        `<button type="button" class="slick-arrow slick-next">
          <svg class="icon" width="20" height="36" viewBox="0 0 20 36">
            <use href="${href}#icon_arrow"></use>
          </svg>
        </button>`
      });

    }

    
  }

  if ($('.article-text').length > 0 && $('.article-text .gallery-widget').length > 0) {
    const id = $('.article-text .gallery-widget').attr('id').split('.')[1];
    fetch(`https://darik.bg/galery.ajax.php?Id=${id}`).then((data )=> data.text()).then((html) => {
      if(html.trim() !== '')
        $('.article-text .gallery-widget').replaceWith(html);
    }).catch(); 
  }

  $('.close-gallery').on('click', function(e) { 
    if (document.referrer.indexOf(window.location.host) !== -1 && document.referrer !== window.location.href) { 
      e.preventDefault(); 
      history.back(); 
    } 
  });

});
